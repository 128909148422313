<!--
 * @Author: lbh
 * @Date: 2022-09-09 11:03:11
 * @LastEditors: lbh
 * @LastEditTime: 2023-01-10 14:16:29
 * @Description: 404
-->
<template>
  <div class="error-box">
    <el-empty
      :image-size="300"
      :image="require('../assets/do-img/404.png')"
      description="哎呀！訪問的頁面出錯了，請重試"
    >
      <div class="btn">
        <router-link
          to="/"
          replace
        >返回首頁</router-link>
      </div>
    </el-empty>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.error-box {
  & /deep/ .el-empty {
    .el-empty__description p {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      margin-top: -50px;
      color: #000000;
    }
    .el-empty__bottom {
      margin-top: -10px;
      .btn {
        width: 80px;
        height: 26px;
        background: var(--themeColor);
        border-radius: 6px;
        line-height: 26px;
        a {
          font-size: 14px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}
</style>